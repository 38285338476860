<template>
  <FormModal title="New Collaborator" endpoint="collaborators" :data="data" v-bind="$attrs" @success="success">
    <InputGroup label="Name" name="name">
      <TextInput v-model="data.name" @input="onNameChange" />
    </InputGroup>

    <InputGroup label="Slug" name="slug">
      <TextInput v-model="data.slug" />
    </InputGroup>
  </FormModal>
</template>

<script>
export default {
  data: () => ({
    data: {
      name: null,
      slug: null
    }
  }),
  methods: {
    onNameChange(name) {
      this.data.slug = name.replace(/\s+/g, "-").toLowerCase()
    },
    success(result) {
      this.$notification.success(`Collaborator **${this.data.name}** successfully created.`)
      this.$emit("close", result)
    }
  }
}
</script>
