<template>
  <AppLayout :title="title">
    <Row lg="row">
      <Column lg="2/3">
        <Card title="Details" :noPadding="true">
          <dl class="dl-horizontal">
            <dt>Name</dt>
            <dd>{{ collaborator.name }}</dd>
            <dt>Slug</dt>
            <dd>{{ collaborator.slug }}</dd>
          </dl>
        </Card>

        <Card title="API Information" :noPadding="true">
          <template v-slot:buttons>
            <Button
              prepend-icon="clipboard"
              v-clipboard="`${collaborator.api_key}:${collaborator.api_secret}`"
              v-clipboard:success="onCopy"
            />
          </template>

          <dl class="dl-horizontal">
            <dt>API Key</dt>
            <dd>{{ collaborator.api_key }}</dd>
            <dt>API Secret</dt>
            <dd>{{ collaborator.api_secret }}</dd>
          </dl>
        </Card>
      </Column>
    </Row>
  </AppLayout>
</template>

<script>
export default {
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    loading: true,
    title: null,
    collaborator: null
  }),
  async created() {
    await this.fetchCollaborator()
  },
  methods: {
    async fetchCollaborator() {
      this.loading = true
      const response = await this.$api.get(`collaborators/${this.$route.params.id}`)
      this.collaborator = response.data.data
      this.title = this.collaborator.name
      this.loading = false
    },
    onCopy() {
      this.$notification.success("Copied!")
    }
  }
}
</script>
