module.exports = {
  appName: "Approach Guides Admin",
  apiPrefix: "admin",
  titleTemplate: "%s | Approach Guides",
  formSuccessMessage: "Successfully updated!",
  copyrightText: `&copy; 2016-${new Date().getFullYear()}, Approach Guides.`,
  iconAlias: {
    collaborator: "chart-network",
    collection: "bullseye",
    content: "photo-video",
    contentDepth: "layer-group",
    copilot: "user-pilot",
    country: "globe-americas",
    "block-heading": "heading",
    "block-cta": "megaphone",
    "block-image": "image",
    "block-note": "sticky-note",
    "block-content": "photo-video",
    consortium: "people-group",
    destination: "map-marker-alt",
    facade: "arrow-alt-circle-right",
    "host-agencies": "buildings",
    language: "language",
    "media-type": "th-large",
    "partner-market": "globe-americas",
    publisher: "typewriter",
    region: "globe-americas",
    subjectArea: "thumbtack"
  },
  dataTable: {
    count: 25
  }
}
