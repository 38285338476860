import { library } from "@fortawesome/fontawesome-svg-core"
import { faJs } from "@fortawesome/free-brands-svg-icons"
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons"

import {
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faArrows,
  faBan,
  faBars,
  faBookOpen,
  faBrowser,
  faBuilding,
  faBuildings,
  faBullseye,
  faCalendarTimes,
  faClone,
  faCompress,
  faCropAlt,
  faDownload,
  faEarthAmerica,
  faEllipsisV,
  faEnvelope,
  faExpandWide,
  faFileAlt,
  faFileLines,
  faFilePdf,
  faFilm,
  faFolder,
  faGem,
  faHeading,
  faImage,
  faImageLandscape,
  faLanguage,
  faLink,
  faLock,
  faLongArrowRight,
  faMagic,
  faMapMarker,
  faMapMarkerAlt,
  faMegaphone,
  faMouse,
  faPalette,
  faPencil,
  faPeopleGroup,
  faPhone,
  faPhoneLaptop,
  faPhotoVideo,
  faPlayCircle,
  faPlus,
  faRedo,
  faStar,
  faStickyNote,
  faSync,
  faTachometerAverage,
  faTachometerFast,
  faTachometerSlow,
  faTag,
  faThLarge,
  faThumbtack,
  faTrash,
  faUserCircle,
  faUsers,
  faWaveform
} from "@fortawesome/pro-regular-svg-icons"

import {
  faArrowAltCircleRight,
  faBadgeCheck,
  faBooks as faBooksSolid,
  faBrowser as faBrowserSolid,
  faBuildings as faBuildingsSolid,
  faBullseye as faBullseyeSolid,
  faCalendarExclamation as faCalendarExclamationSolid,
  faClock as faClockSolid,
  faCodeBranch,
  faDiscDrive as faDiscDriveSolid,
  faEye as faEyeSolid,
  faFileExport as faFileExportSolid,
  faFilter as faFilterSolid,
  faFolder as faFolderSolid,
  faGlobeAmericas as faGlobeAmericasSolid,
  faImage as faImageSolid,
  faLanguage as faLanguageSolid,
  faLayerGroup as faLayerGroupSolid,
  faListOl as faListOlSolid,
  faLongArrowDown,
  faLongArrowUp,
  faMapMarkerAlt as faMapMarkerAltSolid,
  faObjectsColumn,
  faPalette as faPaletteSolid,
  faPeopleGroup as faPeopleGroupSolid,
  faPhotoVideo as faPhotoVideoSolid,
  faRectangleWide as faRectangleWideSolid,
  faSearch as faSearchSolid,
  faServer as faServerSolid,
  faStopwatch as faStopwatchSolid,
  faThLarge as faThLargeSolid,
  faThumbtack as faThumbtackSolid,
  faTypewriter as faTypewriterSolid,
  faUnlink as faUnlinkSolid,
  faUser as faUserSolid,
  faUserCircle as faUserCircleSolid,
  faUserPilot as faUserPilotSolid,
  faUsers as faUsersSolid,
  faWeightHanging as faWeightHangingSolid
} from "@fortawesome/pro-solid-svg-icons"

library.add(
  faJs,
  faSpinnerThird,
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faArrowAltCircleRight,
  faArrows,
  faBan,
  faBars,
  faBookOpen,
  faBrowser,
  faBuilding,
  faBuildingsSolid,
  faBullseye,
  faCalendarTimes,
  faClone,
  faCompress,
  faCropAlt,
  faDownload,
  faEarthAmerica,
  faEllipsisV,
  faEnvelope,
  faExpandWide,
  faFileAlt,
  faFileLines,
  faFilePdf,
  faFilm,
  faFolder,
  faGem,
  faHeading,
  faImage,
  faImageLandscape,
  faLanguage,
  faLink,
  faLock,
  faLongArrowRight,
  faMagic,
  faMapMarker,
  faMapMarkerAlt,
  faMegaphone,
  faMouse,
  faPalette,
  faPencil,
  faPeopleGroup,
  faPhone,
  faPhoneLaptop,
  faPhotoVideo,
  faPlayCircle,
  faPlus,
  faRedo,
  faStar,
  faStickyNote,
  faSync,
  faTachometerAverage,
  faTachometerFast,
  faTachometerSlow,
  faTag,
  faThLarge,
  faTrash,
  faThumbtack,
  faUserCircle,
  faUsers,
  faWaveform,
  faArrowAltCircleRight,
  faBadgeCheck,
  faBooksSolid,
  faBrowser,
  faBrowserSolid,
  faBuildings,
  faBullseyeSolid,
  faCalendarExclamationSolid,
  faCodeBranch,
  faClockSolid,
  faDiscDriveSolid,
  faFileExportSolid,
  faEyeSolid,
  faFilterSolid,
  faFolderSolid,
  faGlobeAmericasSolid,
  faImageSolid,
  faLanguageSolid,
  faLayerGroupSolid,
  faListOlSolid,
  faLongArrowDown,
  faLongArrowUp,
  faMapMarkerAltSolid,
  faObjectsColumn,
  faPaletteSolid,
  faPeopleGroupSolid,
  faPhotoVideoSolid,
  faRectangleWideSolid,
  faSearchSolid,
  faServerSolid,
  faStopwatchSolid,
  faThLargeSolid,
  faThumbtackSolid,
  faTypewriterSolid,
  faUnlinkSolid,
  faUserSolid,
  faUserPilotSolid,
  faUserCircleSolid,
  faUsersSolid,
  faWeightHangingSolid
)
