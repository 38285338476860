<template>
  <AppLayout :title="title">
    <DataTable
      ref="dataTable"
      title="Collaborators"
      endpoint="collaborators"
      :per-page="10"
      :sort="{ views: 'desc' }"
      class="mt-6 lg:mt-12"
    >
      <template v-slot:buttons>
        <Button type="primary" @click="newCollaborator">NEW COLLABORATOR</Button>
      </template>

      <template v-slot:rows="rows">
        <DataTableRow v-for="(row, i) in rows" :key="i" :row="row">
          <DataTableColumn title="Name" sortable="name">
            <router-link :to="{ name: 'collaborators.view', params: { id: row.id } }">{{ row.name }}</router-link>
          </DataTableColumn>
          <DataTableColumn title="Collections" sortable="collections_count">{{
            row.collections_count | number
          }}</DataTableColumn>
        </DataTableRow>
      </template>
    </DataTable>
  </AppLayout>
</template>

<script>
import NewCollaboratorModal from "@/modals/Collaborators/NewCollaboratorModal.vue"

export default {
  metaInfo() {
    return { title: this.title }
  },
  data: () => ({
    title: "Collaborators"
  }),
  methods: {
    async newCollaborator() {
      if (await this.$modal.show(NewCollaboratorModal)) {
        this.$refs.dataTable.reload()
      }
    }
  }
}
</script>
